export default function NewsService(options) {
    this.axios = options.axios
        .create({
            baseURL: options.env.VUE_APP_FIXIT_NO_BOOKING_URL
        });


	this.GetCart = async function(chainId, clientUUId) {
		const result = await this.axios.post(`/api/Cart/get-shopping-cart`, {chainId: chainId, clientUUId: clientUUId});
		if (result.data.responseCode === 200 || result.data.responseCode === 204) {
			const cart = result.data.cart;
			return cart[0];
		}
		return false;
	}
	this.InsertOrUpdateProduct = async function(product, clientUUId) {
		const result = await this.axios.post('/api/Cart/insert-or-update-product',
		{
			clientUUId: clientUUId,
			siteId: product.siteId,
			isCompleted: product.isCompleted,
			salePrice: product.salePrice,
			quantity: product.quantity,
			productId: product.productId,
		});
		if (result.data.responseCode === 200) {
			const cart = result.data.cart;
			return cart[0];
		}
		return false;
	}
	this.InsertOrUpdateTreatment = async function(treatment, siteId, clientUUId) {
		const result = await this.axios.post('/api/Cart/insert-or-update-treatment',
		{
			clientUUId: clientUUId,
			siteId: siteId,
			isCompleted: false,
			treatmentId: treatment.treatmentId || treatment.id,
			salePrice: treatment.pricePerSite.find(x => x.siteId === siteId).price || treatment.price
		});
		if (result.data.responseCode === 200) {
			const cart = result.data.cart;
			return cart[0];
		}
		return false;
	}
	this.DeleteShoppingCartItem = async function(item, chainId, clientUUId, type) {
		let id = ''
		if (item.productId) {
			id = item.productId
		} else if (item.id) {
			id = item.id
		}
		const result = await this.axios.post('/api/Cart/delete-shopping-cart-item',
		{
			clientUUId: clientUUId,
			chainId: chainId,
			productId: type === 'P' ? id : '',
			treatmentId: type === 'P' ? '' : (item.treatmentId || item.id),
			deleteAllItems: false,
		});
		if (result.data.responseCode === 200) {
			const cart = result.data.cart;
			return cart[0];
		}
		return false;
	}

  this.RemoveCartTreatments = async function(req) {
		const result = await this.axios.post('/api/Cart/remove-shopping-cart-treatments',
		{
			siteId: req.siteId,
			treatmentIds: req.treatmentIds,
			clientUUId: req.clientUUId,
		});
		return result.data.responseCode === 200
	}
}
