export function appLinkList () {
    return  {
            banner: "https://picsum.photos/500/300?image=20",
            AppIconAppleAppStoreSvg: "https://res.cloudinary.com/odin-systemer/image/upload/v1620134139/resources/Hjemmeside_Malbilder/app_store.svg",
            AppIconGooglePlayStoreSvg: "https://res.cloudinary.com/odin-systemer/image/upload/v1620134139/resources/Hjemmeside_Malbilder/google_play.svg",
            AppIconHuaweiAppGallerySvg: "https://res.cloudinary.com/odin-systemer/image/upload/v1620134139/resources/Hjemmeside_Malbilder/appgallery.svg",
            AppRedirectUrlAppleAppStore: "http://itunes.apple.com/no/app/fixit-timebestilling/id428497923?mt=8", 
            AppRedirectUrlGooglePlayStore: "http://market.android.com/details?id=no.odinsystemer.fixit&referrer=",
            AppRedirectUrlHuaweiAppGallery: "https://appgallery.huawei.com/#/app/C104239241"
        }
}
