
import { menuItemsList } from "@/static/menuItems";
import { SettingTypeEnum } from "@/static/enums/index.js";
import { waitFor } from "vue-wait";
import CartTabItem from "../components/CartTabItem.vue";
import HomepageFooter from "~/components/HomepageFooter.vue";
import CartList from "../pages/cart/components/CartList.vue";
import { TheFixitNoHeader, TheFixitNoSnackbar } from "@fixit/fixit-no-header";
import { mapWaitingActions } from "vue-wait";
import { Header, Notification, User } from "@fixit/fixit-no-header/src/models";
import { createOpacityVariables } from "@fixit/fixit-no-header/src/utils/opacity-colors";
import FixitDialog from "../components/FixitDialog.vue";
import RecSvc from '@fixit/v-recommendation-box/src/services/rec-svc';
import {
  ItemType,
  Location,
  Channel,
} from "@fixit/v-recommendation-box/src/enums";
export default {
  name: "HomepageLayout",
  head() {
    return {
      link: [
        {
          rel: "icon",
          type: "image/x-icon",
          href: this.$store.getters["cmsConfigHomepage/getLogo"],
        },
      ],
    };
  },
  components: {
    TheFixitNoHeader,
    TheFixitNoSnackbar,
    HomepageFooter,
    CartTabItem,
    CartList,
    FixitDialog,
    RecEngineModule: () => import("../components/RecEngineModule.vue"),
  },
  data() {
    return {
      atTop: true,
      height: 60,
      overrideOptionsBooking: {},
      snackbarState: new Notification(),
      primary: null,
      secondary: null,
      primaryText: null,
      secondaryText: null,
      showHeaderAndFooter: false,
      addedDialog: false,
      addedDialogType: ItemType.T,
      itemType: ItemType,
      location: Location,
      channel: Channel,
    };
  },
  computed: {
    salons() {
      let sites = [];
      this.siteIds.forEach((siteId) => {
        sites.push(this.$store.getters["salon/getSalonById"](siteId));
      });
      return sites;
    },
    selectedSalon() {
      return this.$store.getters['cart/getSelectedSalon']
    },
    preselectedEmployee() {
      return this.$store.getters['cart/getPreselectedEmployee']
    },
    cartSalon() {
      if (this.cart && this.cart.siteId) {
        return this.$store.getters["salon/getSalonById"](this.cart.siteId)
      } else {
        return this.$store.getters["salon/getSalonById"](this.$store.getters['cart/getSelectedSalon'] || this.$store.getters['cart/getPreviousSelectedSalon'] || this.$store.getters['cart/getcart'])
      }
    },
    cartCount() {
      return this.$store.getters["cart/getCartCount"];
    },
    cartType() {
      return this.cartTreatments.length &&
        (this.cartBundles.length || this.cartProducts.length)
        ? ItemType.C
        : !this.cartTreatments.length && (this.cartBundles.length || this.cartProducts.length)
        ? ItemType.P
        : ItemType.T;
    },
    cart() {
      return this.$store.getters["cart/getCart"];
    },
    cartTreatmentIds() {
      return this.$store.getters["cart/getCartTreatments"].map(
        (x) => x.urlSafe
      );
    },
    cartTreatments() {
      return this.$store.getters["cart/getCartTreatments"];
    },
    cartProductAndBundleIds() {
      return this.$store.getters["cart/getCartProductAndBundleIds"]
    },
    cartProducts() {
      return this.$store.getters["cart/getCartProducts"];
    },
    cartBundles() {
      return this.$store.getters["cart/getCartBundles"];
    },
    siteIds() {
      return this.$store.getters["salon/getSiteIds"];
    },
    header() {
      let header = new Header({
        height: 60,
        elevation: 3,
        apiUrl: this.$config.FIXIT_CORE_API_URL,
        oauthUrl: this.$config.VUE_APP_OAUTH_URL,
        fixitNoUrl: this.$config.PORTAL_URL,
        useFixitOauthLogin: true,
        items: menuItemsList().filter((x) => {
          if (x.Caption === "Artikler") {
            x.Visible =
              this.$store.getters["cmsConfigHomepage/newsVisible"] &&
              this.$store.getters["news/getNews"].length;
          }
          if (x.Caption === "Nettbutikk") {
            x.Visible =
              this.$store.getters["cmsConfigHomepage/getWebshopActiveStatus"];
            x.Link = `/nettbutikk/${this.$store.getters["cmsConfigHomepage/getChainId"]}`;
          }
          if (x.Caption === "Gavekort") {
            x.Visible =
              this.$store.getters["cmsConfigHomepage/getGiftcardActiveStatus"];
          }
          return x;
        }),
        layout: "chain",
        atTop: false,
        headerColors: [this.colorSecondary, this.colorSecondary],
        sites: this.salons,
        draftMode: this.$store.getters["cmsConfigHomepage/isDraftMode"],
      });
      return header;
    },
    homepageLogo() {
      return this.$store.getters["cmsConfigHomepage/getLogo"];
    },
    menuItems() {
      return menuItemsList();
    },
    snackbar() {
      return this.snackbarState;
    },
    user() {
      return new User(this.$store.getters["app/get"]("user"));
    },
    colorPrimary() {
      return this.primary;
    },
    colorSecondary() {
      return this.secondary;
    },
    primaryTextColor() {
      return this.primaryText;
    },
    secondaryTextColor() {
      return this.secondaryText;
    },
    chainLogo() {
      return this.$store.getters["cmsConfigHomepage/getLogo"];
    },
    homepageId() {
      return this.$store.getters["cmsConfigHomepage/getHomepageId"];
    },
    isSingleSite() {
      return this.$store.getters['salon/getIsSingleSite']
    },
  },
  async mounted() {
    if (process.client) {
      if (this.$route.query.clientUUid) {
        localStorage.setItem(
          "clientUUid",
          JSON.stringify(this.$route.query.clientUUid)
        );
        this.$router.replace({ query: {} });
      }
      this.showHeaderAndFooter =
        !window.location.pathname.includes("receipt") ||
        (window.location.pathname.includes("receipt") &&
          !window.location.host.includes("receipt.onlinetimebok.no")) ||
        !window.location.pathname.includes("app") ||
        (window.location.pathname.includes("app") &&
          !window.location.host.includes("app.onlinetimebok.no"));
    }
    this.onScroll();
    var settings = this.$store.getters["cmsConfigHomepage/getTemplateSetting"];
    if (settings) {
      var font = settings?.find(
        (t) => t.settingType == SettingTypeEnum.FONT1
      )?.settingValue;
      var font2 = settings?.find(
        (t) => t.settingType == SettingTypeEnum.FONT2
      )?.settingValue;

      this.createElement("primary-font", "font-family", font2);
      this.createElement("secondary-font", "font-family", font);
    }

    if (
      !this.$store.getters["cmsConfigHomepage/getCmsFound"] &&
      !window.location.host.includes("receipt.onlinetimebok.no")
    ) {
      window.open(`${this.$config.PORTAL_URL}`, "_self");
    }

    if (this.isSingleSite) {
      this.$store.commit("cart/setSelectedSalon", this.salons[0].siteId)
    }

    await this.getRecommendations()

    /**
     * Listens for opening and closing of the 'Added to cart' dialog
     */
    this.$nuxt.$on("openAddedToCartDialog", (type) => {
      this.addedDialog = true;
      this.addedDialogType = type;
    });
    this.$nuxt.$on("closeAddedToCartDialog", () => {
      this.addedDialog = false;
    });
  },
  beforeDestroy() {
    this.$nuxt.$off("openAddedToCartDialog");
    this.$nuxt.$off("closeAddedToCartDialog");
  },
  async created() {
    var settings = this.$store.getters["cmsConfigHomepage/getTemplateSetting"];
    //change back after demo
    this.primary = settings?.find(
      (t) => t.settingType == SettingTypeEnum.COLOR1
    )?.settingValue;
    this.secondary = settings?.find(
      (t) => t.settingType == SettingTypeEnum.COLOR2
    )?.settingValue;

    createOpacityVariables(this.primary, this.secondary);
    if (settings) {
      this.primaryText = this.GetReadableForeColorHex(this.primary);
      this.secondaryText = this.GetReadableForeColorHex(this.secondary);
    }
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.onScroll);
    }
  },

  methods: {
    toggleLoginDialog() {
      if (this.$refs.fixitNoHeader) this.$refs.fixitNoHeader.openDialog();
    },
    ...mapWaitingActions("app", {
      getCurrentUser: "loading initailize homepage getCurrentUser",
    }),
    navigationAction(nav) {
      if (this.$nuxt.$router.path !== nav.Link) this.$router.push(nav.Link);
    },
    recommendationIds() {
      return {
        treatmentIds: this.cartTreatments.length > 0 ? this.cartTreatments.map(t => t.urlSafe) : null,
        productIds: this.cartProductAndBundleIds.length > 0 ? this.cartProductAndBundleIds : null
      }
    },
    async getRecommendations(employeeId) {
      let box = {
        userId: this.user.value?.onlineSluttKundeId,
        chainId: this.$store.getters["cmsConfigHomepage/getChainId"],
        siteIds: [ItemType.P].includes(this.cartType) ? this.siteIds : this.cart && this.cart.siteId ? [this.cart.siteId] : this.selectedSalon && this.selectedSalon !== 0 ? [this.selectedSalon] : this.siteIds,
        itemType: ItemType.T,
        overrideText: this.cartSalon && this.cartSalon.appointmentButtonOverrideText ? this.cartSalon.appointmentButtonOverrideText : null,
        location: Location.HOME_PAGE,
        channel: Channel.HomePage,
        quantity: 4,
        cartItemsIds: this.recommendationIds().treatmentIds,
        urlBase: this.$config.VUE_APP_FRE_API_URL,
        employeeId: employeeId || undefined
      }
      const service = await RecSvc();
      const res = await service.getRecommendationItems(box);
      if (res && Array.isArray(res)) {
        this.$store.commit('treatments/setRecEngineItems', res)
      }
    },
    checkoutRedirect() {
      let routeData = this.$router.resolve({
        name: "redirect",
        path: "/checkout",
      });

      localStorage.setItem(
        "expires",
        JSON.stringify(new Date(new Date().getTime() + 10 * 60000))
      );
      localStorage.setItem(
        "currentStep",
        JSON.stringify(
          this.cartType === ItemType.P
            ? this.user.onlineSluttKundeId === 0
              ? "cart"
              : "delivery-method-selection"
            : "time-and-employee-selection"
        )
      );

      window.open(routeData.href, "_self");
    },
    GetReadableForeColorHex(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      var hex = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
      return (hex.r + hex.b + hex.g) / 3 < 128 ? "#FFFFFF" : "#000000";
    },
    createElement(className, prop, value) {
      var style = document.createElement("style");
      document.head.appendChild(style);
      style.sheet.insertRule(
        "." + className + "{" + prop + ":" + value + "!important" + "}"
      );
    },
    onScroll() {
      this.atTop =
        (window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0) === 0;
    },
    setActiveUser(user) {
      if (user.onlineSluttKundeId != 0) {
        this.$store.dispatch("employees/fetchFavoriteEmployees");
        this.$store.dispatch("treatments/fetchFavoriteTreatments");
        this.$store.dispatch("salon/fetchFavoriteSalons");
        this.$store.dispatch("products/fetchFavoriteProducts");
      } else {
        this.$store.commit("employees/setFavoriteEmployees", []);
        this.$store.commit("treatments/setFavoriteTreatments", []);
        this.$store.commit("salon/setFavoriteSalons", []);
        this.$store.commit("products/setFavoriteProducts", []);
      }
      this.$store.commit("app/SET_USER", user);
      if (window) {
        const clientUUid = JSON.parse(localStorage.getItem("clientUUid"));
        this.$store.commit("cart/setClientUUid", clientUUid || null);
      }
      this.$store.dispatch("cart/getCart");
    },
    setSnackbar(snackbar) {
      this.snackbarState = new Notification(snackbar);
    },
    closeSnackbar(snackbar) {
      this.snackbarState = new Notification(snackbar);
    },
    updateTreatments() {
      this.$store.dispatch("treatments/fetchFavoriteTreatments");
    },
    updateEmployees() {
      this.$store.dispatch("employees/fetchFavoriteEmployees");
    },
    updateProducts() {
      this.$store.dispatch("products/fetchFavoriteProducts");
    },
    goHome() {
      this.$router.replace("/");
    },
  },

  watch: {
    $route(newValue, oldValue) {
      if (Object.keys(oldValue.query).length != 0 && this.header.draftMode) {
        this.$router.push({
          query: {
            ...JSON.parse(JSON.stringify(newValue.query)),
            homepageId: this.homepageId,
          },
        });
      }
    },

    user(newValue, oldValue) {
      if (
        newValue.onlineSluttKundeId != oldValue.onlineSluttKundeId &&
        oldValue.onlineSluttKundeId != 0
      ) {
        this.$store.commit("cart/setCart", null);
        this.$store.commit("cart/setClientUUid", null);
        localStorage.removeItem("clientUUid");
      }
    },
    async selectedSalon(newValue, oldValue) {
      if (newValue && newValue !== 0 && !this.isSingleSite) {
        await this.getRecommendations()
        await this.$store.dispatch('employees/getSiteEmployees', newValue)
      }
    },
    async preselectedEmployee(newValue, oldValue) {
      if (newValue && newValue.employeeId) {
        await this.getRecommendations(newValue.employeeId) //get recommendations for employee
      } else {
        await this.getRecommendations() //get recommendations for sites or selected salon
      }
    }
  },
};
